import React, {useState} from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import logo from '../images/logo.svg'
import config from '../../data/config.json'
import {breakpoints} from '../styles/helpers'

const Header = styled.header`
	display: flex;
	align-items: center;
	height: 75px;
	padding: 1rem;
	max-width: 1500px;
	position: relative;
	margin: 0 auto;
	margin-bottom: ${props => props.menu ? '90px' : 0};

	img {
		width: 7.451rem;
		margin-bottom: 0;
	}

	svg {
		width: 1.25rem;
		display: none;
		color: #253858;
		margin-left: auto;

		&:hover {
			color: #0152cc;
		}

		${breakpoints.md} {
			display: initial;
		}
	}

	.menu {
		margin-left: auto;

		${breakpoints.md} {
			text-align: right;
			margin: 0;
			position: absolute;
			right: 0;
			top: 4rem;
			visibility: ${props => props.menu ? 'visible' : "hidden"};

			a {
				display: block;
			}

		}
	}

	h4 {
		display: inline;
		margin-right: 1rem;
		color: #505F79;
		letter-spacing: 1px;
		text-transform: uppercase;

		&:hover {
			color: #0747A6;
		}
	}

	span {
		margin-left: 1rem;
		background: #C1C7D0;
		border-radius: 4px;
		padding: .25rem .5rem;

		a { color: #253858;	}

		&:hover {
			background: #EBECF0;
			a { color: rgb(7, 71, 166); }
		}
	}
`

export default ({page}) => {
	const [open, setOpen] = useState(false)
	return(
		<div>
		<Header menu={open}>
			<div style={{display: 'flex'}}>
				<Link to='/'><img src={logo} alt="Metrum Package Network" /></Link>
				<span><Link to={'/snapshots/' + config.latestSnapshot}>{config.latestSnapshot}</Link></span>
			</div>
			<svg onClick={() => setOpen(!open)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
				<path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 
				6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 
				12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 
				0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"></path>
			</svg>
			<div className="menu">
				<Link to='/'><h4 style={{color: (page === 'index' && '#0747A6')}}>Home</h4></Link>
				<Link to='/snapshots'><h4 style={{color: (page === 'snapshots' && '#0747A6')}}>Snapshots</h4></Link>
				<Link to='/changelog'><h4 style={{color: (page === 'changelog' && '#0747A6')}}>Changelog</h4></Link>
				<Link to='/highlights'><h4 style={{color: (page === 'highlights' && '#0747A6')}}>Highlights</h4></Link>
			</div>
		</Header>
		</div>
	)
}